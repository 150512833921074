import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { logEvent } from "@src/utils/logClient";
import isMobile from "@src/utils/isMobile";
import styled from "@emotion/styled";
import { theme } from "@src/components/nessie-web";

const {
  colors: { dt_white },
} = theme;

const BlankModal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${dt_white};
`;

const DownloadApp = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.download_app_link",
      eventValue: window.location.href,
      metadata: params,
    });

    const isCoveredPlatform = isMobile().apple.device || isMobile().android.device;

    if (isCoveredPlatform) {
      if (isMobile().apple.device) {
        window.location.href = "https://apps.apple.com/us/app/classdojo/id552602056";
      }
      if (isMobile().android.device) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.classdojo.android";
      }
    } else {
      window.location.href = "https://www.classdojo.com/";
    }
  }, [params]);

  return <BlankModal />;
};

export default DownloadApp;
